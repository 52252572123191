@import '../../../../styles/mixins/_element-mixins';
@import '../../../../styles/mixins/_layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.product-result {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  &_image-link {
    width: 100%;
    position: relative;
    height: 100%;
    cursor: pointer;

    .figure {
      position: relative;
      margin: 0;

      .wrapper {
        position: relative;
        margin: 0;
        aspect-ratio: 5/6;
        display: none;

        & img {
          object-fit: cover;
        }

        &:first-child {
          display: block;
        }
      }

      &:hover {
        .wrapper {
          display: block;

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  &_info {
    display: block;
    padding-top: 12px;
    width: 100%;
    box-sizing: border-box;
  }

  &_details {
    line-height: 1.4;
    display: flex;
    h2 {
      @include subHeading4;
      color: #333333;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      @include laptop-bp {
        -webkit-line-clamp: 2;
      }
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &_price {
    @include subHeading4;
    font-family: $font-manrope-normal;
    font-size: $tp-subHeading4-size;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.3px;

    &_wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 8px 0;

      @include laptop-bp {
        padding: 10px 0;
      }
    }

    &_old {
      @include subHeading4;
      font-family: $font-manrope-normal;
      font-size: $tp-subHeading4-size;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.3px;
      text-align: left;
      color: $tp-rebuild-medium-grey;
      text-decoration: line-through;
    }

    &_discounted {
      @include subHeading4;
      font-family: $font-manrope-normal;
      font-size: $tp-subHeading4-size;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.3px;
      text-align: left;
      color: #333333;
    }
  }

  &_options {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    &_colourButton {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid $tp-rebuild-grey-04;
      display: flex;
      align-items: center;
      justify-content: center;

      @include laptop-bp {
        height: 22px;
        width: 22px;
      }

      label {
        display: flex;
        height: 24px;
        width: 24px;
        border-radius: 50%;

        @include laptop-bp {
          height: 16px;
          width: 16px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      &:hover,
      &__selected {
        border: 1px solid $tp-warm-grey;
        cursor: pointer;
      }
    }

    &_moreColours_label {
      font-family: $font-manrope-normal;
      @include laptop-bp {
        font-size: $tp-subHeading4-size;
      }
      font-size: $tp-paragraph-L-size;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.3px;
      cursor: pointer;
    }
  }
  &_colour_swatch_container {
    display: flex;
    justify-content: flex-start;
  }
}
