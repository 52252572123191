@import '../../../../../styles/mixins/font-mixins';

.productCardTag {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;

  position: absolute;
  width: 101px;
  height: 29px;
  left: 0px;
  top: 0px;

  border-radius: 0px;

  .productCardLabel {
    width: 85px;
    height: 21px;

    /* Paragraph Small */

    @include paragraph-S;

    font-family: 'Proxima Nova';
    font-size: 12px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
