@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.container {
  padding: 0 12px;

  &_top {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .count {
        @include subHeading5(Manrope);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2.2px;
        text-transform: uppercase;
      }
    }
  }

  .progressBarContainer {
    margin-block: 52px;
    height: 1px;
    background: rgba(0, 0, 0, 0.3);

    > div {
      height: 5px;
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.title {
  @include subHeading2($font-serif-normal);
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
}

.carouselItems {
  margin-bottom: 8px;
}

.carouselItem {
  margin-inline: 4px;
  height: auto;
}
