@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/config/variables';

.container {
  display: flex;
  position: relative;
  min-width: 0;
  @include laptop-bp() {
    flex: 2;
  }
}

.carousel {
  display: flex;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.items {
  flex-grow: 0;
  display: flex;
  align-items: start;
}

.btn {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: $tp-house-pink;

  @include laptop-bp() {
    width: 56px;
    height: 56px;
  }

  &_left {
    left: 16px;
    @include laptop-bp() {
      left: 32px;
    }
  }

  &_right {
    right: 16px;
    @include laptop-bp() {
      right: 32px;
    }
  }
}
.svg {
  width: 16px;

  & > path {
    stroke: $tp-white;
    stroke-width: 1px;
  }
  @include laptop-bp() {
    height: 12px;
  }

  &_left {
    transform: rotate(-90deg);
  }

  &_right {
    transform: rotate(90deg);
  }
}
